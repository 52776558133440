import {downloadFile, getRequest} from "../routes/Routes";
import {deleteUser} from "../auth/Auth";

export const loadUsers = async (token) => {
    let users = [];
    await getRequest('/api/users', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                users = response.data.users;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return users;
};

export const loadProducts = async () => {
    let products = [];
    await getRequest('/api/products').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                products = response.data.products;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return products;
};

export const loadInvoices = async (token) => {
    let invoices = [];
    await getRequest('/api/invoices', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                invoices = response.data.invoices;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return invoices;
};

export const loadAreaCodes = async () => {
    let areaCodes = [];
    await getRequest('/api/area-codes').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                areaCodes = response.data.areaCodes;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return areaCodes;
};

export const loadNumbers = async () => {
    let numbers = [];
    await getRequest('/api/numbers').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                numbers = response.data.numbers;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return numbers;
};

export const loadOrders = async (token) => {
    let orders = [];
    await getRequest('/api/orders', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                orders = response.data.orders;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return orders;
};

export const loadLimitedOrders = async (token, limit) => {
    let orders = [];
    await getRequest('/api/orders/limit/' + limit, token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                orders = response.data.orders;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return orders;
};

export const loadSubAccountOrders = async (token) => {
    let orders = [];
    await getRequest('/api/orders/sub-accounts', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                orders = response.data.orders;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return orders;
};

export const loadStatistics = async (token) => {
    let statistics = {};
    await getRequest('/api/statistics', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                statistics = response.data.statistics;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return statistics;
};

export const loadMonthlyOrdersStatistics = async (token) => {
    let statistics = {};
    await getRequest('/api/statistics/monthly-orders', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                statistics = response.data.statistics;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return statistics;
};

export const loadDateRangeOrdersStatistics = async (token, start, end) => {
    let statistics = {};
    await getRequest('/api/statistics/date-range-orders/' + start + '/' + end, token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                statistics = response.data.statistics;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return statistics;
};

export const loadDateRangeOrderReport = async (token, start, end, fileType) => {
    let csv = null;
    await downloadFile('/api/reports/date-range-orders/' + start + '/' + end + '/' + fileType, token).then(response => {
        if (response.status === 200) {
            csv = response.data;
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return csv;
};

export const loadUserDetails = async (token) => {
    let user = {};
    await getRequest('/api/users/details', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
                if (response.data.message === 'Not Authorized!') {
                    deleteUser().then(() => {
                        window.location.href = '/login';
                    });
                }
            } else {
                user = response.data.user;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return user;
};

export const loadNotifications = async (token) => {
    let notifications = {};
    await getRequest('/api/notifications', token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                notifications.refillRequests = response.data.refillRequests;
                notifications.orders = response.data.orders;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return notifications;
};

import {useEffect, useState} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './views/home.js';
import Signin from './views/signin.js';

import {getUser} from "./auth/Auth";

import './App.css';

function App() {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    if (user !== undefined) {
        return (
            <Router>
                <Routes>
                    <Route exact path='/' element={user ? <Home/> : <Navigate to="/login"/>}/>
                    <Route path='/login' element={<Signin/>}/>
                    <Route path='*' element={<Navigate to="/"/>}/>
                </Routes>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
            </Router>
        );
    } else {
        return null;
    }
}

export default App;

import React, {useState} from 'react';
import {postRequest} from "../routes/Routes";
import {createUser} from "../auth/Auth";

import '../assests/css/Signin.css';

import logo from "../assests/images/logo.svg";

function Signin() {
    const [inputs, setInputs] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const loginFunction = (event) => {
        setLoading(true);
        event.preventDefault();
        let values = {
            email: event.target.email.value,
            password: event.target.password.value,
            endpoint: '/api/login'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    createUser(response.data.user).then(() => {
                        setError(values => ({...values, error: false, errorMessage: null}));
                        window.location.href = '/';
                    });
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    return (
        <div className="main">
            <div className='d-flex'>
                <div/>
                <div>
                    <form onSubmit={loginFunction}>
                        <div className='img'>
                            <img src={logo} alt="Logo"/>
                        </div>
                        <div>
                            <p className='usernametxt'>Email</p>
                            <input type="email" className='usernamelabel' name="email" value={inputs.email || ""}
                                   onChange={handleChange} required/>
                            <p className='passtxt'>Password</p>
                            <input type="password" className='passwordlabel' name="password"
                                   value={inputs.password || ""}
                                   onChange={handleChange} required/>
                        </div>
                        {error.error &&
                        <div className="alert login-alert alert-danger" role="alert">
                            {error.errorMessage}
                        </div>}
                        <div>
                            <button type="submit" className='btn green-button signinbtnn' disabled={loading}>
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signin;

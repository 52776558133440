import React, {useEffect, useState} from 'react';
import {Chart} from "react-google-charts";
import moment from "moment";

import {createUser, getUser} from "../auth/Auth";
import {postRequest} from "../routes/Routes";
import {loadLimitedOrders, loadMonthlyOrdersStatistics, loadNotifications, loadUserDetails} from "../common/Common";

import Header from "../components/header";
import Accountbalance from "./accountbalance";
import Neworder from "./neworder";
import Orderhistory from "./orderhistory";
import ResellerManager from "./resellermanager";
import ResellerOrder from "./resellerorder";
import ResellerStats from "./resellerstats";
import ProductManager from "./productmanager";

import BlueIcon from "../assests/images/bluedot.png";
import moreicon from "../assests/images/more.png";
import notificationping from "../assests/images/notificationping.png";
import logo from "../assests/images/logo.svg";
import userImage from "../assests/images/user.svg";

import '../assests/css/Home.css';

function Home() {
    const [component, setComponent] = useState("Home");
    const [user, setUser] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [monthlyOrderData, setMonthlyOrderData] = useState([]);

    useEffect(() => {
        getUser().then((data) => {
            loadUserDetails(data.api_token).then((data) => {
                createUser(data).then(() => null);
                setUser(data);
            })
            loadNotifications(data.api_token).then(data => {
                setNotifications(data);
            }).catch(() => null);
            loadMonthlyOrdersStatistics(data.api_token).then(data => {
                data.monthly_order_count = 0;
                data.weekly_order_count = 0;
                data.monthly_sales = 0.00;

                let startOfWeek = moment().startOf('isoWeek'),
                    endOfWeek = moment().endOf('isoWeek'),
                    days = [],
                    day = startOfWeek,
                    monthlyOrderCount = 0;

                while (day <= endOfWeek) {
                    days.push(day.toDate().getDate());
                    day = day.clone().add(1, 'd');
                }
                data.monthly_orders.forEach((order, index) => {
                    if (index !== 0) {
                        data.monthly_order_count += order[1];
                    }
                    if (days.includes(parseInt(order[0]))) {
                        data.weekly_order_count += order[1];
                    }
                });
                data.monthly_order_stats.forEach((order) => {
                    data.monthly_sales += order[1];
                    monthlyOrderCount += order[2];
                });
                data.average_sales = data.monthly_sales > 0 ? Number(data.monthly_sales / monthlyOrderCount).toFixed(2) : Number(0).toFixed(2);
                data.monthly_sales = Number(data.monthly_sales).toFixed(2);
                setMonthlyOrderData(data);
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const changeComponent = (component) => {
        setComponent(component);
    }

    if (user) {
        return (
            <div>
                <div className="nav-detail-container">
                    <div style={{display: "flex"}}>
                        <img src={logo} alt="Logo" style={{width: 75, height: 75, margin: '1%'}}/>
                        <Header component={component} setComponent={changeComponent}/>
                        <div className="user-detail-container">
                            <div style={{width: "15%", cursor: "pointer"}} data-bs-toggle="modal"
                                 data-bs-target="#notificationModal">
                                <button className='btn notificationbell'/>
                                {notifications && (notifications.refillRequests.length > 0 || notifications.orders.length > 0) &&
                                <img src={notificationping} alt="Notification-Ping" className='notificationping'/>
                                }
                            </div>
                            <div className="user-details" data-bs-toggle="modal" data-bs-target="#profileModal">
                                <img src={userImage} alt="user" className='User'/>
                                <div style={{marginLeft: "10%"}}>
                                    <p className='Name'>{user.name}</p>
                                </div>
                            </div>
                            <button className="navbar-toggler navbar-button" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#verticalNavbar"
                                    aria-controls="verticalNavbar">
                                <span className="nav-link menu-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                                     fill="white"
                                     alt="Menu">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"/>
                                </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div className="tab-content portal-tab-container" id="v-pills-tabContent"
                             style={{width: '80%'}}>
                            <h1 className='Nav-Bar-Pagetxt'>{component}</h1>
                            <div className={component === "Home" ? "tab-pane fade show active" : "tab-pane fade"}
                                 id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <HomeContent changeComponent={changeComponent}/>
                            </div>
                            <div
                                className={component === "Account Balance" ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-balance" role="tabpanel" key="balance"
                                aria-labelledby="v-pills-balance-tab">
                                <Accountbalance/>
                            </div>
                            {user.role_id === 1 &&
                            <div
                                className={component === "Product Manager" ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-product-manager" role="tabpanel"
                                key="productManager" aria-labelledby="v-pills-product-manager-tab">
                                <ProductManager/>
                            </div>
                            }
                            <div className={component === "New Order" ? "tab-pane fade show active" : "tab-pane fade"}
                                 id="v-pills-new-order" role="tabpanel" key="newOrder"
                                 aria-labelledby="v-pills-new-order-tab">
                                <Neworder/>
                            </div>
                            <div
                                className={component === "Order History" ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-order-history" role="tabpanel" key="orderHistory"
                                aria-labelledby="v-pills-order-history-tab">
                                <Orderhistory/>
                            </div>
                            {user.role_id !== 4 ? [
                                <div
                                    className={['Seller Manager', "Reseller Manager", "Sub-Reseller Manager"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                    id="v-pills-reseller-manager" role="tabpanel" key="resellerManager"
                                    aria-labelledby="v-pills-reseller-manager-tab">
                                    <ResellerManager/>
                                </div>,
                                <div
                                    className={['Seller Orders', "Reseller Orders", "Sub-Reseller Orders"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                    id="v-pills-reseller-orders" role="tabpanel" key="resellerOrder"
                                    aria-labelledby="v-pills-reseller-orders-tab">
                                    <ResellerOrder/>
                                </div>,
                                <div
                                    className={['Seller Stats', "Reseller Stats", "Sub-Reseller Stats"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                    id="v-pills-reseller-stats" role="tabpanel" key="resellerStats"
                                    aria-labelledby="v-pills-reseller-stats-tab">
                                    <ResellerStats/>
                                </div>,
                            ] : null
                            }
                        </div>
                        <div className="vertical-nav Sidebar offcanvas offcanvas-end" id="verticalNavbar"
                             tabIndex="-1">
                            <p className='Logo'>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                        aria-label="Close"/>
                            </p>
                            <div className="vertical-detail-container">
                                <h3>Account Balance</h3>
                                <p className='Logo'>${user.balance}</p>
                                <button className='green-button Refill_Btn' data-bs-toggle="modal"
                                        data-bs-target="#homeRefillAccountModal" style={{width: '100%', padding: '2%'}}>
                                    {user.role_id === 1 ?
                                        'Refill Account'
                                        : 'Request Refill'
                                    }
                                </button>
                            </div>
                            <div className="quick-stat-container">
                                <div className="vertical-detail-container">
                                    <h3 style={{fontWeight: 'bold', marginBottom: '10%'}}>Quick Stats</h3>
                                    <h4>Weekly Count</h4>
                                    <p className='Logo' style={{
                                        marginTop: '5%',
                                        marginBottom: 0
                                    }}>{monthlyOrderData.weekly_order_count}</p>
                                </div>
                                <div className="vertical-detail-container">
                                    <h4>Monthly Count</h4>
                                    <p className='Logo' style={{
                                        marginTop: '5%',
                                        marginBottom: 0
                                    }}>{monthlyOrderData.monthly_order_count}</p>
                                </div>
                                <div className="vertical-detail-container">
                                    <h4>Average Sales</h4>
                                    <p className='Logo'
                                       style={{marginTop: '5%', marginBottom: 0}}>${monthlyOrderData.average_sales}</p>
                                </div>
                                <div className="vertical-detail-container">
                                    <h4>Monthly Sales</h4>
                                    <p className='Logo'
                                       style={{marginTop: '5%', marginBottom: 0}}>${monthlyOrderData.monthly_sales}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AccountBalanceModal modalId='homeRefillAccountModal'/>
            </div>
        );
    } else {
        return null;
    }
}

export function HomeContent({changeComponent}) {
    const options = {
        title: "30 Day Order History",
        curveType: "function",
        legend: {position: "top"},
        series: [{color: "#032340"}],
        lineWidth: 4,
    };

    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [monthlyOrderData, setMonthlyOrderData] = useState([]);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadLimitedOrders(data.api_token, 5).then(data => {
                setOrders(data);
            }).catch(() => null);
            loadMonthlyOrdersStatistics(data.api_token).then(data => {
                setMonthlyOrderData(data.monthly_orders);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const orderBody = () => {
        let body = [];
        orders.forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{order.reference}</span></td>
                    <td className="carrier-column">
                        <img src={order.product.image_url} alt={order.product.name}
                             className='Verizon_Logo order-logo'/>
                        <span>{order.product.name}</span>
                    </td>
                    <td className="desktop-column">{moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td className="desktop-column">{order.area_code.area_code}</td>
                    <td className="tab-column">x{order.quantity}</td>
                    <td>${order.quantity * order.price}</td>
                    <td className="status-column">
                        {order.status === 'Completed' ?
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            : <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                        }
                        <span>{order.status}</span>
                    </td>
                    <td>
                        <button className='More_Btn' data-bs-toggle="modal" data-bs-target="#homeOrderResultModal"
                                onClick={() => setSelectedOrder(order)}>
                            <img src={moreicon} alt="moreicon" className='moreicon'/>
                        </button>
                    </td>
                </tr>
            )
        });
        return body;
    }

    const orderResultBody = () => {
        let body = [];
        selectedOrder.order_numbers.forEach((orderNumber, index) => {
            body.push(
                <p key={index}>
                    [Phone #: <b>{orderNumber.number.number}</b>, Account #: <b>{orderNumber.number.account}</b>,
                    Pin: <b>{orderNumber.number.pin}</b>]
                </p>
            )
        });
        return body;
    }

    if (user) {
        return (
            <div>
                <div className="graph-container" style={{marginTop: 0}}>
                    <div className="Graphh">
                        {monthlyOrderData.length > 0 &&
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={monthlyOrderData}
                            options={options}
                        />
                        }
                    </div>
                </div>

                <div className='Receno-Barr'>
                    <p className='Recent_Number'>Recent Number Orders</p>
                    <a className="nav-link green-button Plcenew_Btn" id="v-pills-new-order-tab" data-toggle="pill"
                       key="newOrderLink" href="#v-pills-new-order" role="tab" aria-controls="v-pills-new-order"
                       aria-selected="false" onClick={() => changeComponent('New Order')}>
                        Place New Order
                    </a>
                </div>

                <div>
                    <table className='Tableee'>
                        <thead className='Theaddd'>
                        <tr>
                            <th>ORDER</th>
                            <th>CARRIER</th>
                            <th className="column-w-20 desktop-column">DATE</th>
                            <th className="desktop-column">AREA CODE</th>
                            <th className="tab-column">QUANTITY</th>
                            <th>COST</th>
                            <th>STATUS</th>
                            <th>MORE</th>
                        </tr>
                        </thead>
                        <tbody className='Tbodyyy'>
                        {orderBody()}
                        </tbody>
                    </table>
                    <div className="status-detail-container">
                        <div>
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            Completed
                        </div>
                        <div>
                            <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                            Pending
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="homeOrderResultModal" tabIndex="-1"
                     aria-labelledby="homeOrderResultModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" style={{maxWidth: 'fit-content'}}>
                        {selectedOrder &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="homeOrderResultModalLabel">
                                    <b>{selectedOrder.reference}</b> - Order Result
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {orderResultBody()}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Home;

export function AccountBalanceModal(data) {
    const [user, setUser] = useState(null);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => null);
    }, []);

    const handleChange = (event, key) => {
        if (event.target.value !== '') {
            if (parseFloat(user.balance) > parseFloat(event.target.value)) {
                setInvoice(values => ({
                    ...values,
                    method: 'Withdraw',
                    amount: parseFloat(user.balance) - parseFloat(event.target.value),
                    to_user_id: user.id,
                    from_user_id: user.id,
                    generated_by: user.id,
                    direction: 'Outbound',
                }));
            } else {
                setInvoice(values => ({
                    ...values,
                    method: 'Refill',
                    amount: parseFloat(event.target.value) - parseFloat(user.balance),
                    to_user_id: user.id,
                    from_user_id: user.id,
                    generated_by: user.id,
                    direction: 'Inbound',
                }));
            }
        }
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        if (!invoice) {
            setError(values => ({...values, error: true, message: "Please enter an amount!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let values;
        values = {
            ...invoice,
            endpoint: '/api/invoices',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setUser(response.data.toUser);
                    createUser(response.data.toUser).then(() => {
                        window.location.href = '/';
                    });
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    const requestRefillFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        let values = {
            endpoint: '/api/refill-requests',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    if (user) {
        return (
            <div className="modal fade" id={data.modalId} tabIndex="-1"
                 aria-labelledby="refillAccountModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="refillAccountModalLabel">
                                {user.role_id === 1 ? 'Refill Account' : 'Request Refill'}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        {user.role_id === 1 ?
                            <div className="modal-body">
                                <form onSubmit={formSubmit} className="portal-form">
                                    <div className="form-group">
                                        <label htmlFor="balance" className="form-label">Balance</label>
                                        <input className="form-control" type="number"
                                               placeholder='Balance'
                                               id="balance"
                                               min="0"
                                               step=".01"
                                               required
                                               defaultValue={user.balance}
                                               onChange={(event) => handleChange(event, 'balance')}/>
                                    </div>
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.message}
                                    </div>}
                                    {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.message}
                                    </div>}
                                    <button type='submit' className="btn green-button form-button">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Save
                                    </button>
                                    <button type='button' className="btn green-border-button form-button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setSuccess({});
                                                setError({});
                                            }}>
                                        Cancel
                                    </button>
                                </form>
                            </div>
                            : <div className="modal-body">
                                <form onSubmit={requestRefillFormSubmit} className="portal-form">
                                    <p>
                                        Request dealer to refill balance
                                    </p>
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.message}
                                    </div>}
                                    {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.message}
                                    </div>}
                                    <button type='submit' className="btn green-button form-button">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Request
                                    </button>
                                    <button type='button' className="btn green-border-button form-button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setSuccess({});
                                                setError({});
                                            }}>
                                        Cancel
                                    </button>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

import React, {useEffect, useState} from 'react';

import {getUser} from "../auth/Auth";
import {loadInvoices} from "../common/Common";

import {AccountBalanceModal} from "./home";
import Pagination from "../components/pagination/Pagination";

import cardicon from "../assests/images/cardicon.png"
import blueicon from "../assests/images/bluedot.png"

import '../assests/css/Accountbalance.css';
import moment from "moment";

function Accountbalance() {
    const [user, setUser] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadInvoices(data.api_token).then(data => {
                setInvoices(data);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const invoiceBody = (invoices) => {
        let body = [];
        invoices.forEach((invoice, index) => {
            body.push(
                <tr key={index}>
                    <td><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{invoice.reference}</span></td>
                    <td>{invoice.generated_by === user.id ? (invoice.direction === 'Inbound' ? invoice.from.email : invoice.to.email) : (invoice.direction === 'Inbound' ? invoice.to.email : invoice.from.email)}</td>
                    <td className="desktop-column">{moment(new Date(invoice.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td className="tab-column">{invoice.generated_by === user.id ? invoice.direction : invoice.direction === 'Inbound' ? 'Outbound' : 'Inbound'}</td>
                    <td className="invoice-amount-column">
                        {(invoice.generated_by === user.id && invoice.direction === 'Outbound') || (invoice.generated_by !== user.id && invoice.direction === 'Inbound') ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                            </svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   className="bi bi-arrow-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                            </svg>
                        }
                        ${invoice.amount}
                    </td>
                    <td className="desktop-column">{invoice.method}</td>
                    <td className="status-column desktop-column">
                        <img src={blueicon} alt="blueicon" className='BlueIcon'/>
                        <span>{invoice.status}</span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    if (user) {
        const pageSize = 10,
            firstPageIndex = (currentPage - 1) * pageSize,
            lastPageIndex = firstPageIndex + pageSize,
            currentPageInvoices = invoices.slice(firstPageIndex, lastPageIndex);
        return (
            <div>
                <p className='Invoice'>Invoice History</p>
                <div>
                    <table className='Tableee'>
                        <thead className='Theaddd'>
                        <tr>
                            <th>INVOICE ID</th>
                            <th>EMAIL</th>
                            <th className="desktop-column">DATE</th>
                            <th className="tab-column">DIRECTION</th>
                            <th>AMOUNT</th>
                            <th className="desktop-column">METHOD</th>
                            <th className="desktop-column">STATUS</th>
                        </tr>
                        </thead>
                        <tbody className='Tbodyyy'>
                        {invoiceBody(currentPageInvoices)}
                        </tbody>
                    </table>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={invoices.length}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <AccountBalanceModal modalId='balanceRefillAccountModal'/>
            </div>
        );
    } else {
        return null;
    }
}

export default Accountbalance;

import React, {useEffect, useState} from 'react';
import moment from "moment";

import {deleteUser, getUser} from "../auth/Auth";
import {loadNotifications} from "../common/Common";
import {getRequest} from "../routes/Routes";

import '../assests/css/Header.css';

function Header({component, setComponent}) {
    const [user, setUser] = useState(null);
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadNotifications(data.api_token).then(data => {
                setNotifications(data);
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const notificationBody = (notifications) => {
        let body = [];
        body.push(<h4 key="refillHeading" style={{marginBottom: '5%'}}>Refill Requests</h4>);
        if (notifications.refillRequests.length > 0) {
            notifications.refillRequests.forEach((refillRequest, index) => {
                body.push(
                    <div key={'refillRequest' + index} className="row row-cols-3" style={{marginBottom: '5%'}}>
                        <div className="col">
                            {refillRequest.user.name}<br/>
                            <span style={{fontSize: 14}}>{refillRequest.user.email}</span>
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            {moment(new Date(refillRequest.created_at)).format('MMM Do YYYY, h:mm:ss a')}
                        </div>
                        <div className="col">
                            <button className="btn green-button form-button"
                                    onClick={() => markAsRead(refillRequest.id)}>
                                Mark as Read
                            </button>
                        </div>
                    </div>
                )
            });
        } else {
            body.push(<p key="refillParagraph" style={{opacity: 0.5}}>No Refill Requests</p>);
        }
        body.push(<h4 key="orderHeading" style={{marginBottom: '5%'}}>Pending Orders</h4>);
        if (notifications.orders.length > 0) {
            notifications.orders.forEach((order, index) => {
                body.push(
                    <div key={'pendingOrder' + index} className="row row-cols-3" style={{marginBottom: '5%'}}>
                        <div className="col">
                            {order.reference}<br/>
                            <span style={{fontSize: 14}}>{order.user.email}</span>
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            <img src={order.product.image_url} alt={order.product.name}
                                 className='Verizon_Logo order-logo'/>
                            {order.product.name}<br/>
                            {moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            Area Code {order.area_code.area_code}<br/>
                            x{order.quantity}<br/>
                            (x{order.quantity - order.order_numbers.length} Required)<br/>
                            ${order.quantity * order.price}
                        </div>
                    </div>
                )
            });
        } else {
            body.push(<p key="pendingOrderParagraph" style={{opacity: 0.5}}>No Pending Orders</p>);
        }
        return body;
    }

    const markAsRead = (id) => {
        getRequest('/api/refill-requests/mark-as-read/' + id, user.api_token).then(response => {
            if (response.status === 200) {
                if (!response.data.error) {
                    notifications.refillRequests = notifications.refillRequests.filter(function (refillRequest) {
                        return refillRequest.id !== id;
                    });
                    setNotifications({...notifications});
                }
            }
        });
    }

    const logout = () => {
        deleteUser().then(() => {
            setUser(null);
            window.location.href = '/login';
        });
    };

    if (user) {
        return (
            <div className='Nav-Bar'>
                <button className="navbar-toggler vertical-nav-button" type="button"
                        data-bs-toggle="offcanvas" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                         className="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </button>
                <nav className="navbar navbar-expand-lg">
                    <div className="navbar-collapse offcanvas offcanvas-start" id="navbarSupportedContent">
                        <p className='Logo'>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"/>
                        </p>
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item" key="home">
                                <a className={component === 'Home' ? "nav-link active" : "nav-link"}
                                   id="v-pills-home-tab"
                                   data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home"
                                   aria-selected={component === 'Home' ? "true" : "false"}
                                   data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                   onClick={() => setComponent("Home")}>
                                    <span className="Sidebar-Txt">Home</span>
                                </a>
                            </li>
                            <li className="nav-item" key="balance">
                                <a className={component === 'Account Balance' ? "nav-link active" : "nav-link"}
                                   id="v-pills-balance-tab" data-toggle="pill" key="balanceLink"
                                   href="#v-pills-balance" role="tab" aria-controls="v-pills-balance"
                                   aria-selected={component === 'Account Balance' ? "true" : "false"}
                                   data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                   onClick={() => setComponent("Account Balance")}>
                                    <span className="Sidebar-Txt">Balance</span>
                                </a>
                            </li>
                            {user.role_id === 1 &&
                            <li className="nav-item" key="productManager" style={{width: '105%'}}>
                                <a className={component === 'Product Manager' ? "nav-link active" : "nav-link"}
                                   id="v-pills-product-manager-tab" data-toggle="pill"
                                   key="productManagerLink" href="#v-pills-product-manager" role="tab"
                                   aria-controls="v-pills-product-manager"
                                   aria-selected={component === 'Product Manager' ? "true" : "false"}
                                   data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                   onClick={() => setComponent("Product Manager")}>
                                    <span className="Sidebar-Txt">Product Manager</span>
                                </a>
                            </li>
                            }
                            <li className="nav-item" key="newOrder">
                                <a className={component === 'New Order' ? "nav-link active" : "nav-link"}
                                   id="v-pills-new-order-tab" data-toggle="pill" key="newOrderLink"
                                   href="#v-pills-new-order"
                                   role="tab" aria-controls="v-pills-new-order"
                                   aria-selected={component === 'New Order' ? "true" : "false"}
                                   data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                   onClick={() => setComponent("New Order")}>
                                    <span className="Sidebar-Txt">New Order</span>
                                </a>
                            </li>
                            <li className="nav-item" key="orderHistory">
                                <a className={component === 'Order History' ? "nav-link active" : "nav-link"}
                                   id="v-pills-order-history-tab" data-toggle="pill"
                                   key="orderHistoryLink" href="#v-pills-order-history" role="tab"
                                   aria-controls="v-pills-order-history"
                                   aria-selected={component === 'Order History' ? "true" : "false"}
                                   data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                   onClick={() => setComponent("Order History")}>
                                    <span className="Sidebar-Txt">Order History</span>
                                </a>
                            </li>
                            {user.role_id !== 4 ? [
                                <li className="nav-item" key="sellerManager">
                                    <a className={component === 'Seller Manager' || component === "Reseller Manager" || component === "Sub-Reseller Manager" ? "nav-link active" : "nav-link"}
                                       id="v-pills-reseller-manager-tab" data-toggle="pill"
                                       key="resellerManagerLink" href="#v-pills-reseller-manager" role="tab"
                                       aria-controls="v-pills-reseller-manager"
                                       aria-selected={component === 'Seller Manager' || component === "Reseller Manager" || component === "Sub-Reseller Manager" ? "true" : "false"}
                                       data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                       onClick={() => setComponent(user.role_id === 1 ? 'Seller Manager' : user.role_id === 2 ? 'Reseller Manager' : 'Sub-Reseller Manager')}>
                                        <span
                                            className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Manager' : user.role_id === 2 ? 'Reseller Manager' : 'Sub-Reseller Manager'}</span>
                                    </a>
                                </li>,
                                <li className="nav-item" key="sellerOrders">
                                    <a className={component === 'Seller Orders' || component === "Reseller Orders" || component === "Sub-Reseller Orders" ? "nav-link active" : "nav-link"}
                                       id="v-pills-reseller-orders-tab" data-toggle="pill"
                                       key="resellerOrderLink" href="#v-pills-reseller-orders" role="tab"
                                       aria-controls="v-pills-reseller-orders"
                                       aria-selected={component === 'Seller Orders' || component === "Reseller Orders" || component === "Sub-Reseller Orders" ? "nav-link active" : "nav-link"}
                                       data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                       onClick={() => setComponent(user.role_id === 1 ? 'Seller Orders' : user.role_id === 2 ? 'Reseller Orders' : 'Sub-Reseller Orders')}>
                                        <span
                                            className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Orders' : user.role_id === 2 ? 'Reseller Orders' : 'Sub-Reseller Orders'}</span>
                                    </a>
                                </li>,
                                <li className="nav-item" key="sellerStats">
                                    <a className={component === 'Seller Stats' || component === "Reseller Stats" || component === "Sub-Reseller Stats" ? "nav-link active" : "nav-link"}
                                       id="v-pills-reseller-stats-tab" data-toggle="pill"
                                       key="resellerStatsLink" href="#v-pills-reseller-stats" role="tab"
                                       aria-controls="v-pills-reseller-stats"
                                       aria-selected={component === 'Seller Stats' || component === "Reseller Stats" || component === "Sub-Reseller Stats" ? "nav-link active" : "nav-link"}
                                       data-bs-dismiss="offcanvas" data-bs-target="#navbarSupportedContent"
                                       onClick={() => setComponent(user.role_id === 1 ? 'Seller Stats' : user.role_id === 2 ? 'Reseller Stats' : 'Sub-Reseller Stats')}>
                                        <span
                                            className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Stats' : user.role_id === 2 ? 'Reseller Stats' : 'Sub-Reseller Stats'}</span>
                                    </a>
                                </li>,
                            ] : null
                            }
                            <li className="nav-item" key="logout">
                                <a className="nav-link" id="v-pills-logout-tab" data-toggle="pill"
                                   href="#v-pills-logout" role="tab" aria-controls="v-pills-logout"
                                   aria-selected="false" onClick={() => logout()}>
                                    <span className="Sidebar-Txt">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="modal fade" id="profileModal" tabIndex="-1" aria-labelledby="profileModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="profileModalLabel">Profile</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input className="form-control" type="text" placeholder='Name' id="name" required
                                           disabled value={user.name}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input className="form-control" type="email" placeholder='Email' id="email"
                                           required disabled value={user.email}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">Role</label>
                                    <input className="form-control" type="role" placeholder='Role' id="role"
                                           required disabled value={user.role.name}/>
                                </div>
                                <button type='button' className="btn green-border-button form-button"
                                        data-bs-dismiss="modal" style={{marginTop: '5%'}}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="notificationModal" tabIndex="-1"
                     aria-labelledby="notificationModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="notificationModalLabel">Notifications</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {notifications && notificationBody(notifications)}
                                <button type='button' className="btn green-border-button form-button"
                                        data-bs-dismiss="modal" style={{marginTop: '5%'}}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Header;
